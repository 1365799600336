<template>
    <div class="pic_set_horizontal">
        <div class="set w1200">
            <figure v-bind:style="{backgroundImage: 'url(' + storageApi + setPic1 + ')'}"></figure>
            <figure v-bind:style="{backgroundImage: 'url(' + storageApi + setPic2 + ')'}"></figure>
            <figure v-bind:style="{backgroundImage: 'url(' + storageApi + setPic3 + ')'}"></figure>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PicSetHorizontal',
    data: function(){
        return {
            storageApi: process.env.VUE_APP_STORAGE_KEY
        }
    },
    props: {
        setPic1: String,
        setPic2: String,
        setPic3: String
    }
}
</script>

<style scoped lang="scss">
    .pic_set_horizontal{
        margin: 7% 0;
        .set{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            figure{
                display: block;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            }
            figure:nth-child(1){
                width: 100%;
                margin-bottom: 20px;
                padding-bottom: 50%;
            }
            figure:nth-child(2),
            figure:nth-child(3){
                width: calc(50% - 10px);
                padding-bottom: 30%;
            }
            figure:nth-child(2){
                margin-right: 10px;
            }
            figure:nth-child(3){
                margin-left: 10px;
            }
        }
    }
</style>