<template>
    <div class="full_video">
        <video controls playsinline :key="key">
            <source :src="videoUrl">
        </video>
    </div>
</template>

<script>
export default {
    name: 'fullVideo',
    data: function(){
        return {
            key: 1,
        }
    },
    computed: {
        videoUrl() {
            return process.env.VUE_APP_STORAGE_KEY+'/'+ this.set;
        },
    },
    watch: {
        set() {
            this.key += 1;
        },
    },
    props: {
        set: String
    }
}
</script>

<style scoped lang="scss">
.full_video{
    margin: 7% 0;
    video{ 
        width: 100%; 
        max-width: 1400px;
        max-height: 1000px;
        display: block; 
        margin: auto;
    }
}
</style>