<template>
    <div class="pic_full">
        <img :src="storageApi+set" alt="">
    </div>
</template>

<script>
export default {
    name: 'PicFull',
    data: function(){
        return {
            storageApi: process.env.VUE_APP_STORAGE_KEY
        }
    },
    props: {
        set: String
    }
}
</script>

<style scoped lang="scss">
.pic_full{
    margin: 7% 0;
    img{ width: 100%; }
}
</style>