<template>
    <div class="page">
        <publicHeader :ifReverse="Boolean(workInfo.theme.headerReverse)"/>
        <Loading
            :active.sync="isLoading" 
            :can-cancel="true" 
            :on-cancel="onCancel"
            :is-full-page="fullPage">
        </Loading>
            
        
        <section id="workinfo" ref="workinfo" v-bind:class="[{black_theme : workInfo.theme.blackTheme}]">
            <section id="top_pic" v-if="workInfo.keyVision">
                <img :src="storageApi+workInfo.keyVision" alt="">
            </section>
            <section id="top_text" v-if="workInfo.name">
                <div class="inner w1200">
                    <div class="col50">
                        <div class="title">
                            <small class="txt-grey">{{workInfo.name.zh}}</small>
                            <h4 class="txt-bold">{{workInfo.name.en}}</h4>
                        </div>
                    </div>
                    <div class="col50">
                        <p class="lh-l">
                            <span v-html="workInfo.mainInfo"></span>
                        </p>
                        <a :href="workInfo.url" v-if="workInfo.url" target="_blank" rel="noopener noreferrer" class="to_web">
                            <h6 class="txt-bold">
                                <img v-if="workInfo.theme.blackTheme" src="@/assets/img/index/arrow_w.svg" alt="">
                                <img v-else src="@/assets/img/index/arrow.svg" alt="">
                                Website
                            </h6>
                        </a>
                    </div>
                </div>
            </section>
            <section id="work_custom">
                <div class="inner" v-for="(item, index) in workInfo.infoBlock">
                    <TextLeft v-if="item.style_id == 1 && item.align == 'left'" :text="item.description" :title="item.title"/>
                    <TextRight v-else-if="item.style_id == 1 && item.align == 'right'" :text="item.description" :title="item.title"/>
                    <PicLimit v-else-if="item.style_id == 3" :set="item.pc_image"/>
                    <PicFull v-else-if="item.style_id == 4" :set="item.full_pc_imgage"/>
                    <PicSetHorizontal v-else-if="item.style_id == 5" :setPic1="item.img1" :setPic2="item.img2" :setPic3="item.img3"/>
                    <PicSetVertical v-else-if="item.style_id == 6" :setPic1="item.img1" :setPic2="item.img2" :setPic3="item.img3"/>
                    <fullVideo v-else-if="item.style_id == 9" :set="item.film"/>
                </div>
            </section>
            <section id="related">
                <div class="w1400">
                    <h3 class="txt-bold">RELATED PROJECTS</h3>
                    <div class="itembox">
                        <!-- <router-link :to="work_info/item.id" v-for="(item, index) in workInfo.related_works"> -->
                        <article @click="toWorkInfo(item.id)" class="item" :class="classByClassification(item.classification)" v-if="item.show==0"
                         v-for="(item, index) in workInfo.related_works" >
                            <div class="pic_inner" v-if="item.preview_type == 'picture'">
                                <figure v-bind:style="{backgroundImage: 'url('+ storageApi+item.previewPic+')'}"></figure>
                                <div class="text">
                                    <h4 class="txt-bold">
                                        <span class="for_show">{{item.en_name}}</span>
                                        <span class="for_deco">{{item.en_name}}</span>
                                    </h4>
                                    <small class="txt-grey">{{item.zh_name}}</small>
                                </div>
                            </div>
                            <div class="pic_inner" v-if="item.preview_type == 'video'">
                                <figure>
                                    <video muted autoplay loop playsinline webkit-playsinline :key="item.previewPic">
                                        <source :src="storageApi+item.previewPic" >
                                    </video>
                                </figure>
                                <div class="text">
                                    <h4 class="txt-bold">
                                        <span class="for_show">{{item.en_name}}</span>
                                        <span class="for_deco">{{item.en_name}}</span>
                                    </h4>
                                    <small class="txt-grey">{{item.zh_name}}</small>
                                </div>
                            </div>
                        </article>
                        <!-- </router-link> -->
                    </div>
                </div>
                
            </section>
        </section>
        
    </div>
</template>

<script>
import publicHeader from '@/components/public/Header.vue'
import PicSetHorizontal from '@/components/page/work_info/PicSetHorizontal.vue'
import PicSetVertical from '@/components/page/work_info/PicSetVertical.vue'
import PicLimit from '@/components/page/work_info/PicLimit.vue'
import PicFull from '@/components/page/work_info/PicFull.vue'
import TextRight from '@/components/page/work_info/TextRight.vue'
import TextLeft from '@/components/page/work_info/TextLeft.vue'
import fullVideo from '@/components/page/work_info/fullVideo.vue'

// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
    name: 'WorkInfo',
    components: {
        publicHeader,
        PicSetHorizontal,
        PicSetVertical,
        PicLimit,
        PicFull,
        TextLeft,
        TextRight,
        fullVideo,
        Loading
    },
    data: function(){
        return {
            storageApi: process.env.VUE_APP_STORAGE_KEY,
            classByClassification: function(Classification){
                if(Classification == 'website'){return 'web'}
                if(Classification == 'digital event'){return 'event'}
                if(Classification == 'graphic design'){return 'design'}
                if(Classification == 'video'){return 'video'}
                if(Classification == 'interior design'){return 'interior'}
            },
            workInfo: {
                theme: {
                    headerReverse: false
                }
            },
            isLoading: false,
            fullPage: true
        }
    },
    methods: {
        getWorkInfo: function(){
            this.isLoading = true;
            this.axios({
                method: 'get',
                baseURL: process.env.VUE_APP_API_KEY,
                url: '/work?id=' + this.$attrs.workId,
                'Content-Type': 'application/json',
                onDownloadProgress: (progressEvent)=>{
                    console.log(progressEvent)
                }
            })
            .then((result) => { 
                this.workInfo = result.data
                this.isLoading = true;
                setTimeout(() => {
                    this.isLoading = false;
                },2000)
            })
            .catch((err) => { console.error(err) }) 
        },
        toWorkInfo: function(id){
            this.$router.push({path: `/work_info/${id}`})
            // this.$router.go(0)
            // location.reload();
        },
        onCancel() {
            console.log('User cancelled the loader.')
        }
    },
    created: function(){
        this.getWorkInfo();
    },
    watch: {
        '$route' (to, from) {
            console.log(to, from);
            this.getWorkInfo();
        }
    }
}
</script>

<style scoped lang="scss">
    .page{
        position: relative;
    }
    #top_pic{
        img{
            width: 100%;
            display: block;
        }
    }
    #top_text{
        margin: 7% 0;
        .title{
            max-width: 445px;
            margin-right: 30px;
            small{
                margin-bottom: 10px;
                display: inline-block;
            }
        }
        .to_web{
            margin-top: 50px;
            img{
                margin-right: 10px;
            }
            &:hover{
                img{
                    animation: arrow_h 1s infinite;
                }
            }
        }
    }
    #related{
        padding: 7% 0;
        background-color: #fff;
        border-top: $color-light-grey 1px solid;
        h1,h2,h3,h4,h5,h6,p,a,span,.txt-grey{
            color: #000;
        }
        .txt-grey{
            color: $color-grey;
        }
        .itembox{
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            margin-top: 50px;
            .item{
                width: 33.3333%;
                cursor: pointer;
                figure{
                    width: 100%;
                    padding-bottom: 60%;
                    background-size: cover;
                    background-position: center;
                    position: relative;
                    video{
                        display: block;
                        position: absolute;
                        background-color: #000;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                    }
                }
                &:hover{
                    .text{
                        h4{
                            .for_show{
                                &::before{
                                    transform: scaleX(0);
                                }
                            }
                        }
                    }
                }
                &.web{
                    .text{
                        &::before{
                            content: '';
                            background-color: $color-yellow;
                        }
                        h4{
                            .for_deco{
                                background: linear-gradient(180deg, transparent 50%, $color-yellow 50%);
                            }
                        }
                    }
                }
                &.event{
                    .text{
                        &::before{
                            content: '';
                            background-color: $color-red;
                        }
                        h4{
                            .for_deco{
                                background: linear-gradient(180deg, transparent 50%, $color-red 50%);
                            }
                        }
                    }
                }
                &.design{
                    .text{
                        &::before{
                            content: '';
                            background-color: $color-green;
                        }
                        h4{
                            .for_deco{
                                background: linear-gradient(180deg, transparent 50%, $color-green 50%);
                            }
                        }
                    }
                }
                &.video{
                    .text{
                        &::before{
                            content: '';
                            background-color: $color-blue;
                        }
                        h4{
                            .for_deco{
                                background: linear-gradient(180deg, transparent 50%, $color-blue 50%);
                            }
                        }
                    }
                }
                &.interior{
                    .text{
                        &::before{
                            content: '';
                            background-color: $color-purple;
                        }
                        h4{
                            .for_deco{
                                background: linear-gradient(180deg, transparent 50%, $color-purple 50%);
                            }
                        }
                    }
                }
                .text{
                    padding: 25px 15px 10px 0;
                    text-transform: uppercase;
                    position: relative;
                    &::before{
                        content: '';
                        margin-bottom: 20px;
                        display: block;
                        position: relative;
                        width: 50px;
                        height: 5px;
                    }
                    h4{
                        position: relative;
                        .for_show{
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 1;
                            display: block;
                            &::before{
                                content: '';
                                width: 103%;
                                height: 100%;
                                background-color: #fff;
                                position: absolute;
                                bottom: -10px;
                                left: -3.5%;
                                transform-origin: right;
                                transform: scaleX(1);
                                transition: .5s;
                                z-index: -1;
                            }
                        }
                        .for_deco{
                            display: inline;
                            color: transparent;
                            position: relative;
                            top: 0;
                            left: 0;
                            bottom: -10px;
                            left: -2%;
                            opacity: .6;
                            transform: scaleX(0);
                            transform-origin: left;
                            transition: .3s;
                        }
                    }
                    small{
                        margin-top: 10px;
                        display: block;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1023px){
        #top_text{
            .col50{
                width: 100%;
            }
            .title{
                margin-right: 0;
                margin-bottom: 30px;
            }
        }
        #related{
            .itembox{
                .item{
                    width: 50%;
                    margin-bottom: 20px;
                }
            }
        }
    }
    @media screen and (max-width: 768px){
        #top_text{
            .to_web{
                margin-top: 30px;
            }
        }
        #related{
            .itembox{
                margin-top: 30px;
            }
        }
    }
    @media screen and (max-width: 425px){
        #related{
            .itembox{
                .item{
                    width: 100%;
                }
            }
        }
    }
</style>
<style lang="scss">
    .black_theme{
        background-color: #000;
        h1,h2,h3,h4,h5,h6,p,a,span,.txt-grey{
            color: #fff;
        }
    }
</style>