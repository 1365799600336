<template>
    <div class="text_right">
        <div class="w1200">
            <div class="in col50">
                <p class="title txt-bold lh-l" v-if="title">{{title}}</p>
                <p class="lh-l">
                    <span v-html="text"></span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TextRight',
    props: {
        title: String,
        text: String
    }
}
</script>

<style scoped lang="scss">
.text_right{
    margin: 7% 0;
    text-align: right;
    .in{
        text-align: left;
        display: inline-block;
        .title{
            margin-bottom: 10px;
        }
    }
}
@media screen and (max-width: 600px){
    .text_right{
        text-align: left;
        .in{
            width: 100%;
        }
    }
}
</style>