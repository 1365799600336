<template>
    <div class="pic_limit">
        <div class="set w1200">
            <img :src="storageApi+set" alt="">
        </div>
    </div>
</template>

<script>
export default {
    name: 'PicLimit',
    data: function(){
        return {
            storageApi: process.env.VUE_APP_STORAGE_KEY
        }
    },
    props: {
        set: String
    }
}
</script>

<style scoped lang="scss">
.pic_limit{
    margin: 7% 0;
    img{ width: 100%; }
}
</style>