<template>
    <div class="pic_set_vertical">
        <div class="set w1200">
            <figure 
                v-bind:style="{backgroundImage: 'url(' + storageApi + setPic1 + ')'}">
            </figure>
            <div class="vertical_r col50">
                <figure 
                    v-bind:style="{backgroundImage: 'url(' + storageApi + setPic2 + ')'}">
                </figure>
                <figure 
                    v-bind:style="{backgroundImage: 'url(' + storageApi + setPic3 + ')'}">
                </figure>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PicSetVertical',
    data: function(){
        return {
            storageApi: process.env.VUE_APP_STORAGE_KEY
        }
    },
    props: {
        setPic1: String,
        setPic2: String,
        setPic3: String
    }
}
</script>

<style scoped lang="scss">
    .pic_set_vertical{
        margin: 7% 0;
        .set{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            figure{
                display: block;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            }
            > figure:nth-child(1){
                width: calc(50% - 10px);
                padding-bottom: 70%;
            }
            .vertical_r{
                figure:nth-child(1),
                figure:nth-child(2){
                    width: calc(100% - 10px);
                    margin-left: 10px;
                    padding-bottom: 70%;
                }
                figure:nth-child(1){
                    margin-bottom: 20px;
                }
                figure:nth-child(2){
                    margin-top: 20px;
                }
            }
            
        }
    }
</style>